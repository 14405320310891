@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "pixl";
  src: url("./assets/PIX-L___.otf"); /* IE9 Compat Modes */
}

@font-face {
  font-family: "nk57";
  src: url("./assets/nk57-monospace-no-eb.ttf"); /* IE9 Compat Modes */
}

@font-face {
  font-family: "nk57i";
  src: url("./assets/nk57-monospace-no-eb-it.ttf"); /* IE9 Compat Modes */
}

@layer components {
  .text-green-xl {
    color: #4dfe28;
    font-size: 1.953rem;
  }

  .right-unset-important {
    right: unset !important;
  }
  .text-shadow-blue {
    text-shadow: 1px 1px 3px blue;
  }

  .img-filter-after::after {
    background: linear-gradient(
      to top,
      #292828 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .carroussel {
    @apply max-lg:w-5/6 relative mt-20 w-3/5 h-72 overflow-hidden flex justify-around flex-col text-xl;
  }
  .carroussel::after {
    position: absolute;
    right: 0;
    top: 0;
    height: 300px;
    width: 200px;
    z-index: 2;

    background: linear-gradient(
      to right,
      #292828 0%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: rotateZ(180deg);
    content: "";
  }
  .carroussel::before {
    background: linear-gradient(
      to right,
      #292828 0%,
      rgba(255, 255, 255, 0) 100%
    );
    content: "";
    height: 300px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .logo-with-text:hover img {
    opacity: 0.2;
  }
  .logo-with-text:hover span {
    opacity: 1;
  }
}

* {
  font-family: "nk57";
  color: #ffffff;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

body {
  overflow: hidden;
  background-color: rgb(41, 40, 40);
}

.letter-spacing{
  letter-spacing: 3px;
}